<template>
  <div id="app">
    <div id="header">
      <svg id="logo" width="101.47mm" height="10.322mm" version="1.1" viewBox="0 0 101.47 10.322" xmlns="http://www.w3.org/2000/svg" xmlns:cc="http://creativecommons.org/ns#" xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#">
        <metadata>
          <rdf:RDF>
          <cc:Work rdf:about="">
            <dc:format>image/svg+xml</dc:format>
            <dc:type rdf:resource="http://purl.org/dc/dcmitype/StillImage"/>
            <dc:title/>
          </cc:Work>
          </rdf:RDF>
        </metadata>
        <g transform="translate(-55.099 -143.01)">
          <path d="m63.378 144.17c0.35042-0.4286 0.10224-1.1612-0.3933-1.1612h-7.3283c-0.49554 0-0.74372 0.73263-0.3933 1.1612l3.5298 4.3151v3.878h-0.92346c-0.36427 0-0.65962 0.36107-0.65962 0.80642 0 0.0891 0.05904 0.16128 0.13192 0.16128h3.9577c0.07289 0 0.13192-0.0721 0.13192-0.16128 0-0.44535-0.29534-0.80642-0.65962-0.80642h-0.92346v-3.878zm-0.96106-0.19354-0.79154 0.9677h-4.6095l-0.79154-0.9677z" stroke-width=".018233"/>
          <g stroke-width=".29562" aria-label="IMPRACTICAL BAR">
          <path d="m67.517 152.85v0.19708h-2.744v-0.19708l0.84897-0.13644v-6.8069l-0.84897-0.13644v-0.19708h2.744v0.19708l-0.86413 0.13644v6.8069z"/>
          <path d="m69.079 152.47v-6.5643l-0.86413-0.13644v-0.19708h2.0769l2.3347 6.0034 2.3953-6.0034h1.895v0.19708l-0.86413 0.13644v6.8069l0.86413 0.13644v0.19708h-2.7591v-0.19708l0.86413-0.13644v-6.534l-2.7591 6.9585h-0.16676l-2.7895-7.2314v6.5643l0.90961 0.379v0.19708h-2.0011v-0.19708z"/>
          <path d="m80.54 150.15h-1.046v2.5621l0.86413 0.13644v0.19708h-2.744v-0.19708l0.84897-0.13644v-6.8069l-0.84897-0.13644v-0.19708h2.9259q2.7288 0 2.7288 2.2892t-2.7288 2.2892zm-0.12128-4.3358h-0.92477v4.1084h0.92477q1.7131 0 1.7131-1.8647v-0.379q0-1.8647-1.7131-1.8647z"/>
          <path d="m90.378 152.4 0.1516 0.16676q-0.50028 0.57609-1.1067 0.57609t-0.87929-0.24257q-0.25772-0.25772-0.33352-0.86412l-0.18192-1.2583q-0.19708-1.3493-1.5009-1.3493h-0.72769v3.2898l0.86413 0.13644v0.19708h-2.744v-0.19708l0.84897-0.13644v-6.8069l-0.84897-0.13644v-0.19708h2.6075q1.516 0 2.1982 0.48513 0.68221 0.46996 0.68221 1.4402 0 1.607-1.9405 1.8647v0.0606q1.4705 0.19709 1.7131 1.5918l0.10612 0.80348q0.0758 0.46997 0.16676 0.69737 0.10612 0.21224 0.34868 0.21224 0.25772 0 0.57608-0.33352zm-3.8507-6.5795h-0.72769v3.3807h1.046q0.6822 0 1.0612-0.40932 0.379-0.42448 0.379-1.2431v-0.18192q0-1.5463-1.7586-1.5463z"/>
          <path d="m92.092 152.85v0.19708h-2.0011v-0.19708l0.90961-0.379 2.7137-7.0949h0.13644l2.5621 7.3375 0.86413 0.13644v0.19708h-2.744v-0.19708l0.75801-0.12128-0.80349-2.2134h-2.4711l-0.75801 1.9557zm1.2128-5.8215-1.2128 3.2898h2.3347z"/>
          <path d="m103.16 145.38h0.19708v2.3347h-0.19708l-0.57608-1.4554q-0.71253-0.65189-1.6676-0.65189-0.93993 0-1.5615 0.77317-0.6064 0.77316-0.6064 2.0769v1.7131q0 1.3189 0.57608 2.0921 0.59124 0.758 1.5918 0.758 0.48513 0 0.95509-0.19708 0.46996-0.21224 0.80349-0.57608l0.5306-1.5008h0.19708v2.3953h-0.19708l-0.66704-0.46997q-0.71253 0.57609-1.5463 0.57609-1.607 0-2.5317-1.0764-0.92477-1.0915-0.92477-2.835 0-1.7586 0.93993-2.8501 0.95509-1.1067 2.4863-1.1067 0.84897 0 1.5767 0.54576z"/>
          <path d="m109.97 147.65-0.6822-1.8344h-1.5615v6.8979l0.86413 0.13644v0.19708h-2.744v-0.19708l0.84897-0.13644v-6.8979h-1.5312l-0.72768 1.8344h-0.25773v-2.0769h6.0792v2.0769z"/>
          <path d="m113.56 152.85v0.19708h-2.744v-0.19708l0.84897-0.13644v-6.8069l-0.84897-0.13644v-0.19708h2.744v0.19708l-0.86413 0.13644v6.8069z"/>
          <path d="m120 145.38h0.19709v2.3347h-0.19709l-0.57608-1.4554q-0.71253-0.65189-1.6676-0.65189-0.93992 0-1.5615 0.77317-0.6064 0.77316-0.6064 2.0769v1.7131q0 1.3189 0.57608 2.0921 0.59125 0.758 1.5918 0.758 0.48513 0 0.95509-0.19708 0.46997-0.21224 0.80349-0.57608l0.5306-1.5008h0.19709v2.3953h-0.19709l-0.66704-0.46997q-0.71253 0.57609-1.5463 0.57609-1.607 0-2.5318-1.0764-0.92476-1.0915-0.92476-2.835 0-1.7586 0.93992-2.8501 0.95509-1.1067 2.4863-1.1067 0.84896 0 1.5766 0.54576z"/>
          <path d="m122.55 152.85v0.19708h-2.0011v-0.19708l0.90961-0.379 2.7137-7.0949h0.13644l2.5621 7.3375 0.86412 0.13644v0.19708h-2.744v-0.19708l0.75801-0.12128-0.80349-2.2134h-2.4711l-0.75801 1.9557zm1.2128-5.8215-1.2128 3.2898h2.3347z"/>
          <path d="m127.87 145.58h2.744v0.19708l-0.86412 0.13644v6.913h2.7895l0.75801-2.0466h0.25772v2.274h-5.685v-0.19708l0.84897-0.13644v-6.8069l-0.84897-0.13644z"/>
          <path d="m142.41 151.03q0 1.0309-0.75801 1.5312-0.74284 0.48512-2.0163 0.48512h-3.2898v-0.19708l0.84897-0.13644v-6.8069l-0.84897-0.13644v-0.19708h2.8501q1.4857 0 2.1073 0.42449 0.63673 0.40932 0.63673 1.3189 0 1.3492-1.7131 1.7131v0.0606q2.1831 0.28804 2.1831 1.9405zm-2.7743-1.7737h-1.4099v3.5626h1.4099q0.78832 0 1.228-0.4548 0.4548-0.46997 0.4548-1.3341v-0.091q0-0.84897-0.43964-1.2583-0.43965-0.42449-1.2431-0.42449zm-0.43965-3.4414h-0.97024v3.1988h1.0309q0.74285 0 1.137-0.42448 0.40933-0.42449 0.40933-1.1976v-0.0606q0.0152-1.516-1.607-1.516z"/>
          <path d="m144.64 152.85v0.19708h-2.0011v-0.19708l0.9096-0.379 2.7137-7.0949h0.13644l2.5621 7.3375 0.86413 0.13644v0.19708h-2.744v-0.19708l0.75801-0.12128-0.80349-2.2134h-2.4711l-0.758 1.9557zm1.2128-5.8215-1.2128 3.2898h2.3347z"/>
          <path d="m156.42 152.4 0.1516 0.16676q-0.50028 0.57609-1.1067 0.57609-0.60641 0-0.87929-0.24257-0.25772-0.25772-0.33352-0.86412l-0.18193-1.2583q-0.19708-1.3493-1.5008-1.3493h-0.72769v3.2898l0.86413 0.13644v0.19708h-2.744v-0.19708l0.84897-0.13644v-6.8069l-0.84897-0.13644v-0.19708h2.6075q1.516 0 2.1982 0.48513 0.68221 0.46996 0.68221 1.4402 0 1.607-1.9405 1.8647v0.0606q1.4705 0.19709 1.7131 1.5918l0.10612 0.80348q0.0758 0.46997 0.16677 0.69737 0.10612 0.21224 0.34868 0.21224 0.25772 0 0.57608-0.33352zm-3.8507-6.5795h-0.72769v3.3807h1.046q0.68221 0 1.0612-0.40932 0.37901-0.42448 0.37901-1.2431v-0.18192q0-1.5463-1.7586-1.5463z"/>
          </g>
          <g transform="scale(1.2298 .81312)" stroke-width=".21514" aria-label="THE">
          <path d="m54.221 175.95v0.45381h-0.35016v1.8138h-0.58967v-1.8138h-0.34876v-0.45381z"/>
          <path d="m55.711 175.95v2.2676h-0.58967v-0.95244h-0.17648v0.95244h-0.58967v-2.2676h0.58967v0.81097h0.17648v-0.81097z"/>
          <path d="m55.948 175.95h0.98325v0.45381h-0.39358v0.43h0.36837v0.43139h-0.36837v0.49863h0.4328v0.45381h-1.0225z"/>
          </g>
        </g>
      </svg>
    </div>

    <div id="container">
      <div id="container-squeeze">
        <content-box 
          imageName="bar"
          :content="'A home away from home, in a home. If you know, you know. It is what it is. Inconveniently located at ' + randomAddress + '.'"
        />

        <content-box 
          imageName="wine"
          content="Featured drinks: Raso's Rum Punch, Old Fashioned 5k, The Barista, and Well Water Spritzer."
        />

        <content-box 
          imageName="barlight"
          :content="'Open ' + randomHours + '. Please fax 867-5309 for reservations.'"
        />

        <content-box 
          imageName="couch"
          content="Follow the links to our social media for upcoming events."
        />
      </div>
    </div>

    <div id="footer">
      <span id="social-container">
        <social-button icon="facebook"/>
        <social-button icon="instagram"/>
        <social-button icon="twitter"/>
      </span>

      <span>
        Copyright 2021 - The Practical Co.
      </span>
    </div>
  </div>
</template>

<script>
import SocialButton from './components/SocialButton.vue'
import ContentBox from './components/ContentBox.vue'

export default {
  name: 'App',
  components: {
    SocialButton,
    ContentBox
  },
  computed: {
    randomHours: function() {
      var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
      var day1Index = Math.floor(Math.random() * (days.length - 2));
      var day2Index = day1Index + Math.floor(Math.random() * (days.length - day1Index - 1)) + 1;
      var day1 = days[day1Index];
      var day2 = days[day2Index];
      var hourStart = Math.ceil(Math.random() * 12);
      var hourEnd = Math.ceil(Math.random() * 12);
      return `${day1} thru ${day2} ${hourStart}AM - ${hourEnd}PM`;
    },
    randomDate: function () {
      let start = new Date(Date.now());
      let dayOffset = Math.random() * 7;
      let date = new Date(start.getFullYear(), start.getMonth(), start.getDate() + dayOffset);
      let months = ["January", "Feburary", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
      let monthName = months[date.getMonth()];
      return monthName + " " + date.getDate();
    },
    randomAddress: function () {
      let number = Math.round(Math.random() * 2000);
      let names = ["Bar", "Prohibition", "Booze", "Bottle", "Doge", "Raso", "King's", "Coco", "Morgan", "Mike", "Daisy", "Odin", "Speakeasy"];
      let name = names[Math.floor(Math.random() * names.length)];
      let types = ["Way", "Street", "Avenue", "Court", "Lane"];
      let type = types[Math.floor(Math.random() * types.length)];
      return `${number} ${name} ${type}`;
    },
  },
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Josefin+Slab:wght@100;400;500;700&display=swap');

* {
  box-sizing: border-box;
}

body {
  margin: 0px;
}

#app {
  font-family: 'Josefin Slab', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ececec;
}

#header {
  display: flex;
  justify-content: center;
  padding: 25px;
  background-color: #0f0f0f;
}
#logo {
  fill: #ad900e;
  max-width: 600px;
}
svg {
  width:100%;
  height:100%;
  object-fit: cover;
}

#container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

#container-squeeze {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1200px;
}

#footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 16px;
  background-color: #0f0f0f;
  font-size: 16px;
  color: #ad900e;
  margin-top: 10px;
}

#social-container {
  flex-grow: 1;
}

.social-button {
  margin: 5px;
}

h2 {
  font-weight: 700;
  font-size: 30px;
  color: #1b1b1b
}

</style>
